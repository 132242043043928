import { Vue, Component, Watch } from 'vue-property-decorator';
import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormFile,
  BFormTextarea,
  BFormSelect,
  BImg,
  BFormSelectOption,
  BFormCheckbox,
  BListGroup,
  BListGroupItem
} from 'bootstrap-vue';
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate/dist/vee-validate.full';
import {
  ProductCategoryViewModel,
  FileParameter,
  ProductCategoryStatus,
  ProductCategoryOrderNumber,
  UpdateCategoryOrderNumbers
} from '@/api/api';
import ApiClientFactory from '@/api/apiClientFactory';
import {
  ProductCategoryAction,
  ProductCategoryGetter,
  productCategoryNamespace
} from '@/store/productCategory/productCategory.module-types';
import { dispatchProductCategoryAction } from '@/store/productCategory/productCategory.dispatch';
import { productCategoryListDispatcher } from '@/views/productCategory/productCategoryList/store/productCategory-list.module';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import { enumToDropdownOptions } from '@/utility/utils';
import CustomLabel from '@/@core/components/labels/CustomLabel.vue';
import draggable from 'vuedraggable';

@Component({
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BModal,
    BForm,
    BFormFile,
    BFormTextarea,
    BFormSelect,
    BImg,
    ValidationProvider,
    ValidationObserver,
    CustomLabel,
    BFormSelectOption,
    BFormCheckbox,
    draggable,
    BListGroup,
    BListGroupItem
  }
})
export default class ProductCategoryFormModal extends Vue {
  @productCategoryNamespace.Getter(ProductCategoryGetter.productCategory)
  productCategory!: ProductCategoryViewModel;

  input = this.getDefaultInputValue();
  orderingListLoading = false;

  VUE_APP_API_BASE_HOST = process.env.VUE_APP_API_BASE_HOST;
  URL = URL;
  productCategoryStatusOptions: DropdownOption[] = enumToDropdownOptions(
    ProductCategoryStatus
  );

  $refs!: {
    formRules: InstanceType<typeof ValidationProvider>;
    modal: InstanceType<typeof BModal>;
    oderingModal: InstanceType<typeof BModal>;
  };

  orderingList: ProductCategoryOrderNumber[] = [];

  @Watch('productCategory')
  productCategoryUpdated(): void {
    if (this.productCategory) {
      this.input = {
        image: null,
        name: this.productCategory.name as string,
        status: this.productCategory.status as ProductCategoryStatus,
        url: this.productCategory.seoUrl as string,
        metaTitle: this.productCategory.metaTitle as string,
        metaDescription: this.productCategory.metaDescription as string,
        showOnMenu: this.productCategory.showOnMenu as boolean
      };
    } else {
      this.input = this.getDefaultInputValue();
    }
  }

  openCreateModal(): void {
    dispatchProductCategoryAction(ProductCategoryAction.clearProductCategory);
    this.input = this.getDefaultInputValue();
    this.$refs.modal.show();
  }

  async openEditModal(id: number): Promise<void> {
    dispatchProductCategoryAction(
      ProductCategoryAction.loadProductCategory,
      id
    );
    this.$refs.modal.show();
  }

  getDefaultInputValue() {
    return {
      name: '',
      image: null,
      status: ProductCategoryStatus.Active,
      url: '',
      metaTitle: '',
      metaDescription: '',
      showOnMenu: false
    };
  }

  add(): void {
    const client = new ApiClientFactory().productCategoryClient();
    const image: FileParameter = {
      data: this.input.image,
      fileName: (this.input.image as any).name
    };

    client
      .create(
        this.input.name,
        this.input.status,
        this.input.url,
        image,
        this.input.metaTitle,
        this.input.metaDescription,
        this.input.showOnMenu
      )
      .then(() => {
        productCategoryListDispatcher.load();
        this.$refs.modal.hide();
        // show toast
        this.$bvToast.toast('Thêm mới danh mục sản phẩm thành công', {
          title: 'Danh mục sản phẩm',
          toaster: 'b-toaster-bottom-right',
          variant: 'success'
        });
      });
  }

  edit() {
    const client = new ApiClientFactory().productCategoryClient();
    const image: FileParameter | null = this.input.image
      ? {
          data: this.input.image,
          fileName: (this.input.image as any).name
        }
      : null;

    client
      .edit(
        this.productCategory.id,
        this.input.name,
        this.input.status,
        this.input.url,
        image,
        this.input.metaTitle,
        this.input.metaDescription,
        this.input.showOnMenu
      )
      .then(() => {
        productCategoryListDispatcher.load();
        this.$refs.modal.hide();
        // show toast
        this.$bvToast.toast('Chỉnh sửa danh mục sản phẩm thành công', {
          title: 'Danh mục sản phẩm',
          toaster: 'b-toaster-bottom-right',
          variant: 'success'
        });
      });
  }

  imageChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      this.input.image = event.target.files[0];
    }
  }
  submit(): void {
    this.$refs.formRules.validate().then((success) => {
      if (success) {
        if (this.productCategory) {
          this.edit();
        } else {
          this.add();
        }
      }
    });
  }

  openOrderingModel() {
    this.$refs.oderingModal.show();
    this.orderingListLoading = true;

    const client = new ApiClientFactory().productCategoryClient();
    client
      .getListByOrderNumber()
      .then((result) => {
        if (result) {
          this.orderingList = result.sort(
            (a, b) => a.orderNumber - b.orderNumber
          );
        }
      })
      .catch((err) => {
        this.$bvToast.toast('Tải danh sách thất bại', {
          title: 'Danh mục sản phẩm',
          toaster: 'b-toaster-bottom-right',
          variant: 'danger'
        });
      })
      .finally(() => {
        this.orderingListLoading = false;
      });
  }

  onSortOrderingSubmit(data) {
    this.orderingList.forEach((el, index) => {
      el.orderNumber = index;
    });

    const client = new ApiClientFactory().productCategoryClient();
    client
      .updateOrderNumber({
        productCategoryOrderNumbers: this.orderingList
      } as UpdateCategoryOrderNumbers)
      .then((result) => {
        if (result) {
          this.$bvToast.toast('Lưu thành công', {
            title: 'Thứ tự danh mục sản phẩm',
            toaster: 'b-toaster-bottom-right',
            variant: 'success'
          });
        } else {
          this.$bvToast.toast('Lưu thất bại', {
            title: 'Thứ tự danh mục sản phẩm',
            toaster: 'b-toaster-bottom-right',
            variant: 'danger'
          });
        }
      })
      .catch((err) => {
        this.$bvToast.toast('Lưu thất bại', {
          title: 'Thứ tự danh mục sản phẩm',
          toaster: 'b-toaster-bottom-right',
          variant: 'danger'
        });
      })
      .finally(() => {
        this.orderingListLoading = false;
      });
  }
}
